/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col w-7/12">
          <vx-card class="mb-base">
            <div :style="{ display: currentPage === 0 ? 'block' : 'none!important' }">
              <div class="border-white border-solid border-2 rounded mb-5">
                <div class="flex bg-primary-gradient p-5">
                  <feather-icon icon="CommandIcon" svgClasses="mr-4 self-center" />
                  <h3 class="font-bold self-center">Drag & Drop Options</h3>
                </div>
                <div class="p-5">
                  <vue-draggable-container id="wordBank" class="wordBank">
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">nmap</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">-sV</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">-sU</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">-sL</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">--top-ports=100</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">--top-ports=1000</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">-O</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">-Pn</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">nc</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">hping</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">-p 1-1023</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">192.168.2.2</h5>
                    <h5 class="dragBlock my-4 font-semibold cursor-pointer">192.168.2.1-100</h5>
                  </vue-draggable-container>
                </div>
              </div>

              <div class="border-white border-solid border-2 rounded">
                <div class="flex bg-primary-gradient p-5">
                  <feather-icon icon="CommandIcon" svgClasses="mr-4 self-center" />
                  <h3 class="font-bold self-center">Command</h3>
                </div>
                <div class="p-5 grid grid-cols-5 gap-4">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input0" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input1" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input2" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input3" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input4" />
                </div>
              </div>
            </div>

            <div v-if="currentPage === 1">
              <div class="border-white border-solid border-2 rounded mb-5">
                <div class="flex bg-primary-gradient p-5">
                  <feather-icon icon="QuestionIcon" svgClasses="mr-4 self-center" />
                  <h3 class="font-bold self-center">Question Options</h3>
                </div>
                <div class="p-5">
                  <div v-for="item in questions" :key="item.name" class="border-white border-solid border-2 p-3 my-2">
                    <div class="flex justify-between items-center">
                      <h2 class="mr-2 font-bold">{{ item.name }}</h2>
                      <vs-switch color="success" class="ml-2" v-model="item.value" />
                    </div>
                    <small v-if="item.marked && item.answer === true && item.marked === item.answer" class="mt-2 text-success font-semibold"
                      >This answer is correct</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center mt-6">
              <vs-button
                class="shadow-lg"
                color="success"
                icon="icon-arrow-right"
                icon-pack="feather"
                icon-after
                v-if="currentPage === 0"
                @click="changePage('next')"
                >+Next Page
              </vs-button>
              <vs-button
                class="shadow-lg mr-2"
                color="danger"
                icon="icon-arrow-left"
                icon-pack="feather"
                v-else
                @click="changePage('previous')"
                >Previous Page
              </vs-button>
              <p class="ml-4">Page: {{ currentPage + 1 }}</p>
            </div>
          </vx-card>
        </div>
        <div class="vx-col w-5/12">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">NMAP Scan Output</h3>
            <div
              class="p-4 text-success"
              style="backdrop-filter: blur(1000px); background-color: rgb(0 0 0 / 44%); max-height: 50vh; overflow: auto"
            >
              <div class="nmap-command-prompt">
                Host is up (0.00079s latency)<br />Net shown: 06 closed ports<br />PORT STATS&nbsp;SERVICE&nbsp;VERSION<br />88/tcp open
                kerberos-sec?<br />139/tcp open netbios-ssn<br />389/tcp open ldap?<br />445/tcp open microsoft-ds?<br />MAC&nbsp;Address:
                80:00:27:81:B1:DF (Oracle VirtualBox virtual NIC)<br />Device type: general purpose<br />Running: Linux 2.4.X<br />OS&nbsp;CPE:
                cpe:/o:linux_kernel:2.4.21<br />OS&nbsp;details: Linux 2.4.21<br />Network Distance: 1hop<br /><br />OS and Service
                detection performed. Please report any incorrect results at https://nmap.org/submit/.<br /># Scan done at Fri Oct 13
                10:03:06 2017 - 1 IP address (1 host up)scanned in 26.80 seconds<br />‍
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import draggable from 'vuedraggable';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      currentPage: 0,

      questions: [
        {
          name: 'Null Session Enumeration',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'Weak SMB file permissions',
          value: false,
          answer: true,
          marked: null,
        },
        {
          name: 'FTP anonymous login',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'SNMP enumeration',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'Fragmentation attack',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'ARP spoofing',
          value: false,
          answer: true,
          marked: null,
        },
        {
          name: 'Webdav file upload',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'Weak Apache Tomcat Credentials',
          value: false,
          answer: false,
          marked: null,
        },
      ],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'nmap') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input0').textContent !== '') {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === '-O') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input1').textContent !== '') {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === '-sV') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input2').textContent !== '') {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === '--top-ports=100') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input3').textContent !== '') {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === '192.168.2.2') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input4').textContent !== '') {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      this.questions.forEach((question, index) => {
        if (question.value === question.answer) {
          this.questions[index].marked = true;
          if (question.answer === true) {
            totalScore++;
          }
        } else {
          this.questions[index].marked = false;
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    changePage(val) {
      if (val === 'next') {
        this.currentPage++;
      }
      if (val === 'previous') {
        this.currentPage--;
      }
    },
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
  },
  components: {
    Prism,
    draggable,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
